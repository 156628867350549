import Button from "@mui/material/Button";
import summary_statistics from "../static/images/summary_statistics.jpg";
import { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { useLoading, BallTriangle } from "@agney/react-loading";

function SummaryStatistics() {
  const [loading, setLoading] = useState(false);
  const [downloadReady, setDownloadReady] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <BallTriangle class="inline" width="50" />,
  });
  const loadingSection = loading ? (
    <div
      className="flex-col align-middle space-y-10 justify-center mt-12 mb-12"
      {...containerProps}
    >
      {indicatorEl}
      <p class="font-light">
        This process will take a while, depending on the size of your data.{" "}
      </p>
    </div>
  ) : downloadReady ? (
    <p class="pt-8 font-light">
      Your file is ready. Make sure to download it before leaving the page.
    </p>
  ) : errorMessage ? (
    <div className="w-full px-4 pt-16">
      <div className="mx-auto w-full max-w-full rounded-2xl p-2">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-red-100 px-4 py-2 text-left text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>An error has occurred.</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-red-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm bg-white text-gray-500">
                <h5 className="font-bold text-md pb-3">
                  Please contact parc.datamanagement@vito.be and send them this
                  error and context, to get help.
                </h5>
                <code>{errorMessage.toString()}</code>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  ) : (
    <></>
  );
  async function main_der_vars() {
    const pyodideWorker = new Worker("/webworker-desc-stats.js");
    try {
      setLoading(true);
      if (document.getElementById("file-desc-stats").files[0] !== null) {
        var file = document.getElementById("file-desc-stats").files[0];
        var reader = new FileReader();
        reader.readAsArrayBuffer(file);
        let uint8_view;
        reader.onload = () => {
          uint8_view = new Uint8Array(reader.result);
          pyodideWorker.postMessage(uint8_view);
          pyodideWorker.onmessage = (e) => {
            console.log(e);
            if (e.data.csv !== undefined) {
              var csv = document.getElementById("download-csv");
              csv.href = window.URL.createObjectURL(e.data.csv);
              csv.download = "summary-stats.csv";

              setDownloadReady(true);
              setLoading(false);
            }

            if (!(e.data.err === undefined)) {
              setErrorMessage(e.data.err);
              setLoading(false);
            }
          };
          console.log("Told worker to parse file.");
        };
      }
    } catch (e) {
      console.log(
        `Error in pyodideWorker at ${e.filename}, Line: ${e.lineno}, ${e.message}, ${e}`
      );
    }
  }
  return (
    <div>
      <section class="bg-white min-h-screen">
        <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">
            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl">
              Human biomonitoring calculation of summary statistics
            </h1>
            <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
            This tool calculates summary statistics on the enriched dataset obtained from the Derived Variables Tool. 
            The tool runs locally on your PC, therefore the individual data are not stored but stays exclusively on your local PC. 
            Depending on the dataset size the time to complete the calculations can vary from a few minutes up to a few hours.   
            </p>
            <a
              href="#sum_stats_tool"
              class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center border rounded-lg focus:ring-4 focus:ring-gray-100"
            >
              Get started
            </a>
          </div>
          <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={summary_statistics} alt="mockup"></img>
          </div>
        </div>
      </section>
      <section className="flex min-h-screen justify-center bg-gray-50">
        <div class="w-full max-w-3xl justify-evenly">
          <div id="errors" className="mt-96"></div>
          <span class="anchor" id="sum_stats_tool"></span>
          <div class="flex w-full justify-evenly">
            <Button variant="outlined" component="label">
              Select file
              <input
                type="file"
                onInput={main_der_vars}
                id="file-desc-stats"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                hidden
              />
            </Button>

            <Button
              id="download-csv"
              variant="outlined"
              component="a"
              href="#"
              disabled={!downloadReady}
            >
              Download CSV
            </Button>
            
          </div>
          <div className="w-full">{loadingSection}</div>
        </div>
      </section>
    </div>
  );
}
export default SummaryStatistics;
